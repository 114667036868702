// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bespoke-approach-js": () => import("./../../../src/pages/bespoke/approach.js" /* webpackChunkName: "component---src-pages-bespoke-approach-js" */),
  "component---src-pages-bespoke-contact-js": () => import("./../../../src/pages/bespoke/contact.js" /* webpackChunkName: "component---src-pages-bespoke-contact-js" */),
  "component---src-pages-bespoke-js": () => import("./../../../src/pages/bespoke.js" /* webpackChunkName: "component---src-pages-bespoke-js" */),
  "component---src-pages-bespoke-projects-js": () => import("./../../../src/pages/bespoke/projects.js" /* webpackChunkName: "component---src-pages-bespoke-projects-js" */),
  "component---src-pages-commercial-about-js": () => import("./../../../src/pages/commercial/about.js" /* webpackChunkName: "component---src-pages-commercial-about-js" */),
  "component---src-pages-commercial-contact-js": () => import("./../../../src/pages/commercial/contact.js" /* webpackChunkName: "component---src-pages-commercial-contact-js" */),
  "component---src-pages-commercial-js": () => import("./../../../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-commercial-our-experience-js": () => import("./../../../src/pages/commercial/our-experience.js" /* webpackChunkName: "component---src-pages-commercial-our-experience-js" */),
  "component---src-pages-commercial-sectors-js": () => import("./../../../src/pages/commercial/sectors.js" /* webpackChunkName: "component---src-pages-commercial-sectors-js" */),
  "component---src-pages-group-js": () => import("./../../../src/pages/group.js" /* webpackChunkName: "component---src-pages-group-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-[project]-js": () => import("./../../../src/templates/[project].js" /* webpackChunkName: "component---src-templates-[project]-js" */),
  "component---src-templates-[sector]-js": () => import("./../../../src/templates/[sector].js" /* webpackChunkName: "component---src-templates-[sector]-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

